body {
	--color-background: #ffffff;
	--color-foreground: #000000;
	--color-link: #2b239e;

	background-color: var(--color-background);
	color: var(--color-foreground);
	margin: 0;
	padding: 0;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
	line-height: 1.5;
}

footer {
	text-align: center;
	font-size: x-small;
	margin: 20px 5px 5px 5px;
	padding-bottom: 5px;
	
}

table {
    border: '1px rgb(255, 0, 0) solid';
    border-collapse: collapse;

}