.Aegean { font-family: 'Noto Sans Linear B', sans-serif; }

.Ahom { font-family: 'Noto Serif Ahom', serif; }

.Arabic { font-family: 'Noto Sans Arabic', sans-serif; }

.Balinise { font-family: 'Noto Sans Balinese', sans-serif; }

.Bengali { font-family: 'Noto Sans Bengali', sans-serif; }

.Brahmi { font-family: 'Noto Sans Brahmi', sans-serif; }

.Burmese { font-family: 'Noto Sans Myanmar', sans-serif; }

.Cham { font-family: 'Noto Sans Cham', sans-serif; }

.Devanagari {    font-family: 'Noto Sans Devanagari', sans-serif; }

.Dzongkha { font-family: 'Noto Serif Tibetan', serif; }

.Egyptian { 
    font-family: 'Noto Sans Egyptian Hieroglyphs', sans-serif;
    font-weight: bold;
}

.Kharoshti { font-family: 'Noto Sans Kharoshthi', serif; }

.Gujarati { font-family: 'Noto Sans Gujarati', sans-serif; }

.Gurmukhi { font-family: 'Noto Sans Gurmukhi', sans-serif; }

.Japanese { font-family: 'Noto Serif JP', serif; }

.Hebrew {
    font-family: 'Noto Serif Hebrew', serif;
    direction: rtl;
    unicode-bidi: bidi-override !important;
    direction: unset !important;
}

.Kannada { font-family: 'Noto Sans Kannada', sans-serif; }

.Khojki { font-family: 'Noto Sans Khojki', sans-serif; }

.Khmer { font-family: 'Noto Sans Khmer', sans-serif; }

.Khudawadi { font-family: 'Noto Sans Khudawadi', sans-serif; }

.Lao { font-family: 'Noto Sans Lao', sans-serif; }

.Limbu { font-family: 'Noto Sans Limbu', sans-serif; }

.Malayalam { font-family: 'Noto Sans Malayalam', sans-serif; }

.Mangolian { font-family: 'Noto Sans Mongolian', sans-serif; }

.Mayan {
   font-family: 'Noto Sans Mayan Numerals', sans-serif;
   writing-mode: vertical-lr;
}

.Meitei {
    font-family: 'Noto Sans Meetei Mayek', sans-serif;
}

.Mesopotamian {
    font-family: 'Noto Sans Cuneiform', sans-serif;
}


.Modi { font-family: 'Noto Sans Modi', sans-serif; }

.Multani { font-family: 'Noto Sans Multani', sans-serif; }

.Nandinagari { font-family: 'Noto Sans Nandinagari', sans-serif; }

.Persian { font-family: 'Noto Kufi Arabic', sans-serif; }

.Lepcha { font-family: 'Noto Sans Lepcha', sans-serif; }

.MasaramGondi { font-family: 'Noto Sans Masaram Gondi', sans-serif; }

.Roman { font-family: 'Noto Sans Symbols', sans-serif; }

.Santali { font-family: 'Noto Sans Ol Chiki', sans-serif; }

.Saurashtra { font-family: 'Noto Sans Saurashtra', sans-serif; }

.Sharada { font-family: 'Noto Sans Sharada', sans-serif; }

.Sinhala { font-family: 'Noto Serif Sinhala', serif; }

.SinhalaIllakkam { font-family: 'Noto Serif Sinhala', serif; }

.SorangSompeng { font-family: 'Noto Sans Sora Sompeng', sans-serif; }

.Sundanese { font-family: 'Noto Sans Sundanese', sans-serif; }

.Telugu { font-family: 'Noto Sans Telugu', sans-serif; }

.Thai { font-family: 'Noto Sans Thai Looped', sans-serif; }

.Tibetan { font-family: 'Noto Serif Tibetan', serif; }

.Tirhuta { font-family: 'Noto Sans Tirhuta', sans-serif; }

.Urdu { font-family: 'Noto Nastaliq Urdu', serif; }

.Odia { font-family: 'Noto Sans Oriya', sans-serif; }

.Tamil { font-family: 'Noto Sans Tamil', sans-serif; }

.Western { font-family: 'Times New Roman', serif; }

.Symbol { font-family: 'Noto Sans Symbols', sans-serif; }

.Symbol { font-family: 'Noto Sans Symbols 2', sans-serif; }
